

















import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import ExerciseForm from '@/components/forms/exercise/ExerciseForm.vue'
import CourseProgramMonthMixin from '@/mixins/manager/CourseProgramMonthMixin'
import { ExerciseLargeResource, ExerciseStore } from '@/store/types'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { convertDateToMSK, convertHoursMinutesToSeconds } from '@/utils/functions'
import AuthModule from '@/store/modules/auth'

@Component({
  components: { ExerciseForm },
})
export default class CoursesItemProgramMonthExercisesNew extends Mixins(CourseProgramMonthMixin) {
  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }
  private handleSubmit (form: ExerciseStore) {
    const formSubmit = {
      ...form,
      avgExecutionSeconds: convertHoursMinutesToSeconds(form.avgExecutionSeconds as unknown as string),
      deadlineAt: form.deadlineAt ? convertDateToMSK(form.deadlineAt, this.isLocalTimezone) : form.deadlineAt,
      spendingAt: form.spendingAt ? convertDateToMSK(form.spendingAt, this.isLocalTimezone) : form.spendingAt,
    }

    ManagerCoursesModule.saveExercise({
      courseID: this.courseID,
      monthID: this.monthID,
      params: formSubmit,
    })
      .then((response: ExerciseLargeResource) => {
        this.notifySuccess('Основа задания сохранена')
        if (this.month) {
          this.$router.push({
            name: 'manager.control.courses.item.program.month.exercises.item.edit',
            params: {
              courseID: this.month.course.id.toString(),
              exerciseUUID: response.uuid,
              monthID: response.monthId.toString(),
            },
          })
        }
      })
      .catch(this.notifyError)
  }

  private destroyed() {
    this.$bus.$off('add-exercise-from-template', this.fetchMonth as any)
  }

  private metaInfo (): MetaInfo {
    return {
      title: `Создание домашнего задания. ${this.monthName}. ${this.month ? this.courseTitle : ''}`,
    }
  }
}
